import React from 'react';
import Layout from '../components/layout';
import * as textCss from './modules/text.module.scss';

const ExpositionAvenirPage = () => {
    return (
        <Layout>
            <div className={textCss.container}>
                avenir
            </div>
        </Layout>
    )
};

export default ExpositionAvenirPage;
